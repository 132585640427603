



































import Component from 'vue-class-component'

import BaseView from '@/views/BaseView'

@Component
export default class NotAuthorized extends BaseView {
  get isDark(): boolean {
    return this.$store.state.appConfig.layout.skin === 'dark'
  }

  created(): void {
    if (this.plexusStore.isLogged) {
      this.$router.push({
        path: '/',
        replace: true
      })
      return;
    }
    
    this.$watch(() => this.plexusStore.isLogged, this.onLoggedStateChanged)
  }

  onLoggedStateChanged(isLogged: boolean, wasLogged: boolean): void {
    // console.log('onLoggedStateChanged', isLogged)
    if (!wasLogged && isLogged) {
      this.$router.push({
        path: '/',
        replace: true
      })
    }
  }
}
